export const DialogsLoginDialog = () => import('../../components/dialogs/LoginDialog.vue' /* webpackChunkName: "components/dialogs-login-dialog" */).then(c => wrapFunctional(c.default || c))
export const UiKitBadge = () => import('../../components/ui-kit/Badge.vue' /* webpackChunkName: "components/ui-kit-badge" */).then(c => wrapFunctional(c.default || c))
export const UiKitBasePopup = () => import('../../components/ui-kit/BasePopup.vue' /* webpackChunkName: "components/ui-kit-base-popup" */).then(c => wrapFunctional(c.default || c))
export const UiKitCard = () => import('../../components/ui-kit/Card.vue' /* webpackChunkName: "components/ui-kit-card" */).then(c => wrapFunctional(c.default || c))
export const UiKitCollapse = () => import('../../components/ui-kit/Collapse.vue' /* webpackChunkName: "components/ui-kit-collapse" */).then(c => wrapFunctional(c.default || c))
export const UiKitDropdown = () => import('../../components/ui-kit/Dropdown.vue' /* webpackChunkName: "components/ui-kit-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UiKitPageHeader = () => import('../../components/ui-kit/PageHeader.vue' /* webpackChunkName: "components/ui-kit-page-header" */).then(c => wrapFunctional(c.default || c))
export const UiKitTooltip = () => import('../../components/ui-kit/Tooltip.vue' /* webpackChunkName: "components/ui-kit-tooltip" */).then(c => wrapFunctional(c.default || c))
export const ClientAuthCookieInfo = () => import('../../components/Client/Auth/CookieInfo.vue' /* webpackChunkName: "components/client-auth-cookie-info" */).then(c => wrapFunctional(c.default || c))
export const ClientCalendarPractice = () => import('../../components/Client/Calendar/CalendarPractice.vue' /* webpackChunkName: "components/client-calendar-practice" */).then(c => wrapFunctional(c.default || c))
export const ClientCalendarSmall = () => import('../../components/Client/Calendar/CalendarSmall.vue' /* webpackChunkName: "components/client-calendar-small" */).then(c => wrapFunctional(c.default || c))
export const ClientCalendarHomeTaskCalendar = () => import('../../components/Client/Calendar/HomeTaskCalendar.vue' /* webpackChunkName: "components/client-calendar-home-task-calendar" */).then(c => wrapFunctional(c.default || c))
export const ClientCalendarPopupAddRecordT = () => import('../../components/Client/Calendar/PopupAddRecordT.vue' /* webpackChunkName: "components/client-calendar-popup-add-record-t" */).then(c => wrapFunctional(c.default || c))
export const ClientCommonFooter = () => import('../../components/Client/Common/CommonFooter.vue' /* webpackChunkName: "components/client-common-footer" */).then(c => wrapFunctional(c.default || c))
export const ClientCommonMobileHeader = () => import('../../components/Client/Common/MobileHeader.vue' /* webpackChunkName: "components/client-common-mobile-header" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageCertificateBanner = () => import('../../components/Client/MainPage/CertificateBanner.vue' /* webpackChunkName: "components/client-main-page-certificate-banner" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageShareBanner = () => import('../../components/Client/MainPage/ShareBanner.vue' /* webpackChunkName: "components/client-main-page-share-banner" */).then(c => wrapFunctional(c.default || c))
export const ClientEmpty = () => import('../../components/Client/PersonalArea/ClientEmpty.vue' /* webpackChunkName: "components/client-empty" */).then(c => wrapFunctional(c.default || c))
export const ClientInfoSkeleton = () => import('../../components/Client/PersonalArea/ClientInfoSkeleton.vue' /* webpackChunkName: "components/client-info-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ClientNotify = () => import('../../components/Client/PersonalArea/ClientNotify.vue' /* webpackChunkName: "components/client-notify" */).then(c => wrapFunctional(c.default || c))
export const ClientPersonalAreaHomeTask = () => import('../../components/Client/PersonalArea/HomeTask.vue' /* webpackChunkName: "components/client-personal-area-home-task" */).then(c => wrapFunctional(c.default || c))
export const ClientPersonalAreaHomeTaskNew = () => import('../../components/Client/PersonalArea/HomeTaskNew.vue' /* webpackChunkName: "components/client-personal-area-home-task-new" */).then(c => wrapFunctional(c.default || c))
export const ClientPersonalAreaLastSession = () => import('../../components/Client/PersonalArea/LastSession.vue' /* webpackChunkName: "components/client-personal-area-last-session" */).then(c => wrapFunctional(c.default || c))
export const ClientPersonalAreaLastSessionList = () => import('../../components/Client/PersonalArea/LastSessionList.vue' /* webpackChunkName: "components/client-personal-area-last-session-list" */).then(c => wrapFunctional(c.default || c))
export const ClientPersonalAreaPlannedSession = () => import('../../components/Client/PersonalArea/PlannedSession.vue' /* webpackChunkName: "components/client-personal-area-planned-session" */).then(c => wrapFunctional(c.default || c))
export const ClientPersonalAreaPlannedSessionList = () => import('../../components/Client/PersonalArea/PlannedSessionList.vue' /* webpackChunkName: "components/client-personal-area-planned-session-list" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupBasePopup = () => import('../../components/Client/Popup/BasePopup.vue' /* webpackChunkName: "components/client-popup-base-popup" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupConfirmPopup = () => import('../../components/Client/Popup/ConfirmPopup.vue' /* webpackChunkName: "components/client-popup-confirm-popup" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupAllPromocodes = () => import('../../components/Client/Popup/PopupAllPromocodes.vue' /* webpackChunkName: "components/client-popup-all-promocodes" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupAllSubscriptions = () => import('../../components/Client/Popup/PopupAllSubscriptions.vue' /* webpackChunkName: "components/client-popup-all-subscriptions" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupBusySlot = () => import('../../components/Client/Popup/PopupBusySlot.vue' /* webpackChunkName: "components/client-popup-busy-slot" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupCancel = () => import('../../components/Client/Popup/PopupCancel.vue' /* webpackChunkName: "components/client-popup-cancel" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupCancelClose = () => import('../../components/Client/Popup/PopupCancelClose.vue' /* webpackChunkName: "components/client-popup-cancel-close" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupCancelWhy = () => import('../../components/Client/Popup/PopupCancelWhy.vue' /* webpackChunkName: "components/client-popup-cancel-why" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupClientState = () => import('../../components/Client/Popup/PopupClientState.vue' /* webpackChunkName: "components/client-popup-client-state" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupDefaultTimezone = () => import('../../components/Client/Popup/PopupDefaultTimezone.vue' /* webpackChunkName: "components/client-popup-default-timezone" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupDelete = () => import('../../components/Client/Popup/PopupDelete.vue' /* webpackChunkName: "components/client-popup-delete" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupDeleteSession = () => import('../../components/Client/Popup/PopupDeleteSession.vue' /* webpackChunkName: "components/client-popup-delete-session" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupEdit = () => import('../../components/Client/Popup/PopupEdit.vue' /* webpackChunkName: "components/client-popup-edit" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupError = () => import('../../components/Client/Popup/PopupError.vue' /* webpackChunkName: "components/client-popup-error" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupFeedbackForm = () => import('../../components/Client/Popup/PopupFeedbackForm.vue' /* webpackChunkName: "components/client-popup-feedback-form" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupFeedbackSuccess = () => import('../../components/Client/Popup/PopupFeedbackSuccess.vue' /* webpackChunkName: "components/client-popup-feedback-success" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupHomeWork = () => import('../../components/Client/Popup/PopupHomeWork.vue' /* webpackChunkName: "components/client-popup-home-work" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupHomeWorkUpload = () => import('../../components/Client/Popup/PopupHomeWorkUpload.vue' /* webpackChunkName: "components/client-popup-home-work-upload" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupLogout = () => import('../../components/Client/Popup/PopupLogout.vue' /* webpackChunkName: "components/client-popup-logout" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupNotify = () => import('../../components/Client/Popup/PopupNotify.vue' /* webpackChunkName: "components/client-popup-notify" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupOverlay = () => import('../../components/Client/Popup/PopupOverlay.vue' /* webpackChunkName: "components/client-popup-overlay" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupPasswordSuccess = () => import('../../components/Client/Popup/PopupPasswordSuccess.vue' /* webpackChunkName: "components/client-popup-password-success" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupPasswordUnsuccess = () => import('../../components/Client/Popup/PopupPasswordUnsuccess.vue' /* webpackChunkName: "components/client-popup-password-unsuccess" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupPayment = () => import('../../components/Client/Popup/PopupPayment.vue' /* webpackChunkName: "components/client-popup-payment" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupProfileVideo = () => import('../../components/Client/Popup/PopupProfileVideo.vue' /* webpackChunkName: "components/client-popup-profile-video" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupQuestion = () => import('../../components/Client/Popup/PopupQuestion.vue' /* webpackChunkName: "components/client-popup-question" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupSessionRate = () => import('../../components/Client/Popup/PopupSessionRate.vue' /* webpackChunkName: "components/client-popup-session-rate" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupSuccess = () => import('../../components/Client/Popup/PopupSuccess.vue' /* webpackChunkName: "components/client-popup-success" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupThanks = () => import('../../components/Client/Popup/PopupThanks.vue' /* webpackChunkName: "components/client-popup-thanks" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupUtc = () => import('../../components/Client/Popup/PopupUtc.vue' /* webpackChunkName: "components/client-popup-utc" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupWarning = () => import('../../components/Client/Popup/PopupWarning.vue' /* webpackChunkName: "components/client-popup-warning" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupWorkingHours = () => import('../../components/Client/Popup/PopupWorkingHours.vue' /* webpackChunkName: "components/client-popup-working-hours" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupWorkingHoursSlots = () => import('../../components/Client/Popup/PopupWorkingHoursSlots.vue' /* webpackChunkName: "components/client-popup-working-hours-slots" */).then(c => wrapFunctional(c.default || c))
export const ClientPopupWorkingHoursT = () => import('../../components/Client/Popup/PopupWorkingHoursT.vue' /* webpackChunkName: "components/client-popup-working-hours-t" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileAbout = () => import('../../components/Client/Profile/ProfileAbout.vue' /* webpackChunkName: "components/client-profile-about" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileArticle = () => import('../../components/Client/Profile/ProfileArticle.vue' /* webpackChunkName: "components/client-profile-article" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileMaterials = () => import('../../components/Client/Profile/ProfileMaterials.vue' /* webpackChunkName: "components/client-profile-materials" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileSpecialistCard = () => import('../../components/Client/Profile/SpecialistCard.vue' /* webpackChunkName: "components/client-profile-specialist-card" */).then(c => wrapFunctional(c.default || c))
export const ClientSearchManualSearchBanner = () => import('../../components/Client/Search/ManualSearchBanner.vue' /* webpackChunkName: "components/client-search-manual-search-banner" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionBookingTimer = () => import('../../components/Client/Session/BookingTimer.vue' /* webpackChunkName: "components/client-session-booking-timer" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionHomeWork = () => import('../../components/Client/Session/HomeWork.vue' /* webpackChunkName: "components/client-session-home-work" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionPopupEvaluation = () => import('../../components/Client/Session/PopupEvaluation.vue' /* webpackChunkName: "components/client-session-popup-evaluation" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionFeedback = () => import('../../components/Client/Session/SessionFeedback.vue' /* webpackChunkName: "components/client-session-feedback" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionInfo = () => import('../../components/Client/Session/SessionInfo.vue' /* webpackChunkName: "components/client-session-info" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionTimer = () => import('../../components/Client/Session/SessionTimer.vue' /* webpackChunkName: "components/client-session-timer" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionWrapper = () => import('../../components/Client/Session/SessionWrapper.vue' /* webpackChunkName: "components/client-session-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ClientSettingsPopupConfirmPhone = () => import('../../components/Client/Settings/PopupConfirmPhone.vue' /* webpackChunkName: "components/client-settings-popup-confirm-phone" */).then(c => wrapFunctional(c.default || c))
export const ClientSettingsPopupDeleteProfile = () => import('../../components/Client/Settings/PopupDeleteProfile.vue' /* webpackChunkName: "components/client-settings-popup-delete-profile" */).then(c => wrapFunctional(c.default || c))
export const ClientSpecialistProfileSpecialistData = () => import('../../components/Client/Specialist/ProfileSpecialistData.vue' /* webpackChunkName: "components/client-specialist-profile-specialist-data" */).then(c => wrapFunctional(c.default || c))
export const ClientSpecialistBlockNotFound = () => import('../../components/Client/Specialist/SpecialistBlockNotFound.vue' /* webpackChunkName: "components/client-specialist-block-not-found" */).then(c => wrapFunctional(c.default || c))
export const ClientSpecialistCard = () => import('../../components/Client/Specialist/SpecialistCard.vue' /* webpackChunkName: "components/client-specialist-card" */).then(c => wrapFunctional(c.default || c))
export const ClientSpecialistDetails = () => import('../../components/Client/Specialist/SpecialistDetails.vue' /* webpackChunkName: "components/client-specialist-details" */).then(c => wrapFunctional(c.default || c))
export const ClientSpecialistMethods = () => import('../../components/Client/Specialist/SpecialistMethods.vue' /* webpackChunkName: "components/client-specialist-methods" */).then(c => wrapFunctional(c.default || c))
export const BlocksCalendarCalendars = () => import('../../components/blocks/calendar/Calendars.vue' /* webpackChunkName: "components/blocks-calendar-calendars" */).then(c => wrapFunctional(c.default || c))
export const BlocksCallsDevicesCheckFAQClient = () => import('../../components/blocks/calls/DevicesCheckFAQClient.vue' /* webpackChunkName: "components/blocks-calls-devices-check-f-a-q-client" */).then(c => wrapFunctional(c.default || c))
export const BlocksCallsDevicesCheckFAQPsychologist = () => import('../../components/blocks/calls/DevicesCheckFAQPsychologist.vue' /* webpackChunkName: "components/blocks-calls-devices-check-f-a-q-psychologist" */).then(c => wrapFunctional(c.default || c))
export const BlocksLogin = () => import('../../components/blocks/login/Login.vue' /* webpackChunkName: "components/blocks-login" */).then(c => wrapFunctional(c.default || c))
export const BlocksLoginRecovery = () => import('../../components/blocks/login/Recovery.vue' /* webpackChunkName: "components/blocks-login-recovery" */).then(c => wrapFunctional(c.default || c))
export const BlocksLoginRegistration = () => import('../../components/blocks/login/Registration.vue' /* webpackChunkName: "components/blocks-login-registration" */).then(c => wrapFunctional(c.default || c))
export const BlocksPromocodeLinkUserByPromocode = () => import('../../components/blocks/promocode/LinkUserByPromocode.vue' /* webpackChunkName: "components/blocks-promocode-link-user-by-promocode" */).then(c => wrapFunctional(c.default || c))
export const BlocksPromocodePromocodes = () => import('../../components/blocks/promocode/Promocodes.vue' /* webpackChunkName: "components/blocks-promocode-promocodes" */).then(c => wrapFunctional(c.default || c))
export const BlocksShopBanner = () => import('../../components/blocks/shop/Banner.vue' /* webpackChunkName: "components/blocks-shop-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksShopCertificate = () => import('../../components/blocks/shop/Certificate.vue' /* webpackChunkName: "components/blocks-shop-certificate" */).then(c => wrapFunctional(c.default || c))
export const BlocksShopSubscription = () => import('../../components/blocks/shop/Subscription.vue' /* webpackChunkName: "components/blocks-shop-subscription" */).then(c => wrapFunctional(c.default || c))
export const UiAttachFile = () => import('../../components/UI/Attach/UiAttachFile.vue' /* webpackChunkName: "components/ui-attach-file" */).then(c => wrapFunctional(c.default || c))
export const UiBadge = () => import('../../components/UI/Badge/UiBadge.vue' /* webpackChunkName: "components/ui-badge" */).then(c => wrapFunctional(c.default || c))
export const UiGoBack = () => import('../../components/UI/Button/UiGoBack.vue' /* webpackChunkName: "components/ui-go-back" */).then(c => wrapFunctional(c.default || c))
export const UiCalendar = () => import('../../components/UI/Calendar/UiCalendar.vue' /* webpackChunkName: "components/ui-calendar" */).then(c => wrapFunctional(c.default || c))
export const UiTableDay = () => import('../../components/UI/Calendar/UiTableDay.vue' /* webpackChunkName: "components/ui-table-day" */).then(c => wrapFunctional(c.default || c))
export const UiTableWeek = () => import('../../components/UI/Calendar/UiTableWeek.vue' /* webpackChunkName: "components/ui-table-week" */).then(c => wrapFunctional(c.default || c))
export const UiTimeSlot = () => import('../../components/UI/Calendar/UiTimeSlot.vue' /* webpackChunkName: "components/ui-time-slot" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/UI/Checkbox/UiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiCheckboxComponent = () => import('../../components/UI/Checkbox/UiCheckboxComponent.vue' /* webpackChunkName: "components/ui-checkbox-component" */).then(c => wrapFunctional(c.default || c))
export const UiCheckboxGroup = () => import('../../components/UI/Checkbox/UiCheckboxGroup.vue' /* webpackChunkName: "components/ui-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const UiCheckboxGroupComponent = () => import('../../components/UI/Checkbox/UiCheckboxGroupComponent.vue' /* webpackChunkName: "components/ui-checkbox-group-component" */).then(c => wrapFunctional(c.default || c))
export const UiDateblockItem = () => import('../../components/UI/Dateblock/UiDateblockItem.vue' /* webpackChunkName: "components/ui-dateblock-item" */).then(c => wrapFunctional(c.default || c))
export const UiFooter = () => import('../../components/UI/Footer/UiFooter.vue' /* webpackChunkName: "components/ui-footer" */).then(c => wrapFunctional(c.default || c))
export const UIForm = () => import('../../components/UI/Form/Form.vue' /* webpackChunkName: "components/u-i-form" */).then(c => wrapFunctional(c.default || c))
export const UIFormWrapper = () => import('../../components/UI/Form/FormWrapper.vue' /* webpackChunkName: "components/u-i-form-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../components/UI/Icon/UiIcon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/UI/Input/UiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiInputComponent = () => import('../../components/UI/Input/UiInputComponent.vue' /* webpackChunkName: "components/ui-input-component" */).then(c => wrapFunctional(c.default || c))
export const UiInputWithCheckbox = () => import('../../components/UI/Input/UiInputWithCheckbox.vue' /* webpackChunkName: "components/ui-input-with-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiPhoneInput = () => import('../../components/UI/Input/UiPhoneInput.vue' /* webpackChunkName: "components/ui-phone-input" */).then(c => wrapFunctional(c.default || c))
export const UiLabel = () => import('../../components/UI/Label/UiLabel.vue' /* webpackChunkName: "components/ui-label" */).then(c => wrapFunctional(c.default || c))
export const UiLink = () => import('../../components/UI/Links/UiLink.vue' /* webpackChunkName: "components/ui-link" */).then(c => wrapFunctional(c.default || c))
export const UiCardSkeleton = () => import('../../components/UI/Loader/UiCardSkeleton.vue' /* webpackChunkName: "components/ui-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const UiLoaderComponent = () => import('../../components/UI/Loader/UiLoaderComponent.vue' /* webpackChunkName: "components/ui-loader-component" */).then(c => wrapFunctional(c.default || c))
export const UiMenuBottom = () => import('../../components/UI/MenuLeftNew/UiMenuBottom.vue' /* webpackChunkName: "components/ui-menu-bottom" */).then(c => wrapFunctional(c.default || c))
export const UiMenuItem = () => import('../../components/UI/MenuLeftNew/UiMenuItem.vue' /* webpackChunkName: "components/ui-menu-item" */).then(c => wrapFunctional(c.default || c))
export const UiMenuLeft = () => import('../../components/UI/MenuLeftNew/UiMenuLeft.vue' /* webpackChunkName: "components/ui-menu-left" */).then(c => wrapFunctional(c.default || c))
export const UiMenuUser = () => import('../../components/UI/MenuLeftNew/UiMenuUser.vue' /* webpackChunkName: "components/ui-menu-user" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/UI/Pagination/UiPagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiPaginationPages = () => import('../../components/UI/Pagination/UiPaginationPages.vue' /* webpackChunkName: "components/ui-pagination-pages" */).then(c => wrapFunctional(c.default || c))
export const UiPaginationVisibleItems = () => import('../../components/UI/Pagination/UiPaginationVisibleItems.vue' /* webpackChunkName: "components/ui-pagination-visible-items" */).then(c => wrapFunctional(c.default || c))
export const UiRadioBlock = () => import('../../components/UI/Radio/UiRadioBlock.vue' /* webpackChunkName: "components/ui-radio-block" */).then(c => wrapFunctional(c.default || c))
export const UiRadioButton = () => import('../../components/UI/Radio/UiRadioButton.vue' /* webpackChunkName: "components/ui-radio-button" */).then(c => wrapFunctional(c.default || c))
export const UiRadioItem = () => import('../../components/UI/Radio/UiRadioItem.vue' /* webpackChunkName: "components/ui-radio-item" */).then(c => wrapFunctional(c.default || c))
export const UiSearchInput = () => import('../../components/UI/SearchInput/UiSearchInput.vue' /* webpackChunkName: "components/ui-search-input" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/UI/Select/UiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiSelectSlotTime = () => import('../../components/UI/Select/UiSelectSlotTime.vue' /* webpackChunkName: "components/ui-select-slot-time" */).then(c => wrapFunctional(c.default || c))
export const UiSelectSlotTimeReactive = () => import('../../components/UI/Select/UiSelectSlotTimeReactive.vue' /* webpackChunkName: "components/ui-select-slot-time-reactive" */).then(c => wrapFunctional(c.default || c))
export const UiSelectWithCheckbox = () => import('../../components/UI/Select/UiSelectWithCheckbox.vue' /* webpackChunkName: "components/ui-select-with-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiSwitcher = () => import('../../components/UI/Switcher/UiSwitcher.vue' /* webpackChunkName: "components/ui-switcher" */).then(c => wrapFunctional(c.default || c))
export const UiTableField = () => import('../../components/UI/Table/UiTableField.vue' /* webpackChunkName: "components/ui-table-field" */).then(c => wrapFunctional(c.default || c))
export const UiTab = () => import('../../components/UI/Tabs/UiTab.vue' /* webpackChunkName: "components/ui-tab" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/UI/Tabs/UiTabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))
export const UiTexarea = () => import('../../components/UI/Textarea/UiTexarea.vue' /* webpackChunkName: "components/ui-texarea" */).then(c => wrapFunctional(c.default || c))
export const UiTextComponent = () => import('../../components/UI/Textarea/UiTextComponent.vue' /* webpackChunkName: "components/ui-text-component" */).then(c => wrapFunctional(c.default || c))
export const UiTextareaWithCheckbox = () => import('../../components/UI/Textarea/UiTextareaWithCheckbox.vue' /* webpackChunkName: "components/ui-textarea-with-checkbox" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSearchApplicationBadges = () => import('../../components/psychologist/search/ApplicationBadges.vue' /* webpackChunkName: "components/psychologist-search-application-badges" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSearchFilterPsychologists = () => import('../../components/psychologist/search/FilterPsychologists.vue' /* webpackChunkName: "components/psychologist-search-filter-psychologists" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSearchGroups = () => import('../../components/psychologist/search/Groups.vue' /* webpackChunkName: "components/psychologist-search-groups" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSessionExpectedSessions = () => import('../../components/psychologist/session/ExpectedSessions.vue' /* webpackChunkName: "components/psychologist-session-expected-sessions" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSessionFinishedSessions = () => import('../../components/psychologist/session/FinishedSessions.vue' /* webpackChunkName: "components/psychologist-session-finished-sessions" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSessionCard = () => import('../../components/psychologist/session/SessionCard.vue' /* webpackChunkName: "components/psychologist-session-card" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSessionTopics = () => import('../../components/psychologist/session/Topics.vue' /* webpackChunkName: "components/psychologist-session-topics" */).then(c => wrapFunctional(c.default || c))
export const PsychologistSessionTopicsModal = () => import('../../components/psychologist/session/TopicsModal.vue' /* webpackChunkName: "components/psychologist-session-topics-modal" */).then(c => wrapFunctional(c.default || c))
export const UiKitAlertsAlert = () => import('../../components/ui-kit/alerts/Alert.vue' /* webpackChunkName: "components/ui-kit-alerts-alert" */).then(c => wrapFunctional(c.default || c))
export const UiKitButtonsButton = () => import('../../components/ui-kit/buttons/Button.vue' /* webpackChunkName: "components/ui-kit-buttons-button" */).then(c => wrapFunctional(c.default || c))
export const UiKitButtonsButtonLink = () => import('../../components/ui-kit/buttons/ButtonLink.vue' /* webpackChunkName: "components/ui-kit-buttons-button-link" */).then(c => wrapFunctional(c.default || c))
export const UiKitButtonsIconButton = () => import('../../components/ui-kit/buttons/IconButton.vue' /* webpackChunkName: "components/ui-kit-buttons-icon-button" */).then(c => wrapFunctional(c.default || c))
export const UiKitFormCheckbox = () => import('../../components/ui-kit/form/Checkbox.vue' /* webpackChunkName: "components/ui-kit-form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiKitFormInput = () => import('../../components/ui-kit/form/Input.vue' /* webpackChunkName: "components/ui-kit-form-input" */).then(c => wrapFunctional(c.default || c))
export const UiKitFormPhoneInput = () => import('../../components/ui-kit/form/PhoneInput.vue' /* webpackChunkName: "components/ui-kit-form-phone-input" */).then(c => wrapFunctional(c.default || c))
export const UiKitFormSelect = () => import('../../components/ui-kit/form/Select.vue' /* webpackChunkName: "components/ui-kit-form-select" */).then(c => wrapFunctional(c.default || c))
export const UiKitFormTextarea = () => import('../../components/ui-kit/form/Textarea.vue' /* webpackChunkName: "components/ui-kit-form-textarea" */).then(c => wrapFunctional(c.default || c))
export const UiKitFormToggle = () => import('../../components/ui-kit/form/Toggle.vue' /* webpackChunkName: "components/ui-kit-form-toggle" */).then(c => wrapFunctional(c.default || c))
export const UiKitLoadingIndicator = () => import('../../components/ui-kit/loading/LoadingIndicator.vue' /* webpackChunkName: "components/ui-kit-loading-indicator" */).then(c => wrapFunctional(c.default || c))
export const UiKitWysiwygEditor = () => import('../../components/ui-kit/wysiwyg/Editor.vue' /* webpackChunkName: "components/ui-kit-wysiwyg-editor" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageStories = () => import('../../components/Client/MainPage/stories/Stories.vue' /* webpackChunkName: "components/client-main-page-stories" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageStoriesStory = () => import('../../components/Client/MainPage/stories/Story.vue' /* webpackChunkName: "components/client-main-page-stories-story" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageWidgetsClosestSession = () => import('../../components/Client/MainPage/widgets/ClosestSession.vue' /* webpackChunkName: "components/client-main-page-widgets-closest-session" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageWidgetsMyPromocodes = () => import('../../components/Client/MainPage/widgets/MyPromocodes.vue' /* webpackChunkName: "components/client-main-page-widgets-my-promocodes" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageWidgetsMySubscriptions = () => import('../../components/Client/MainPage/widgets/MySubscriptions.vue' /* webpackChunkName: "components/client-main-page-widgets-my-subscriptions" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageWidgetsWidget = () => import('../../components/Client/MainPage/widgets/Widget.vue' /* webpackChunkName: "components/client-main-page-widgets-widget" */).then(c => wrapFunctional(c.default || c))
export const ClientMainPageWidgets = () => import('../../components/Client/MainPage/widgets/Widgets.vue' /* webpackChunkName: "components/client-main-page-widgets" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileFormArticlePart = () => import('../../components/Client/Profile/Form/ArticlePart.vue' /* webpackChunkName: "components/client-profile-form-article-part" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileFormPersonalPart = () => import('../../components/Client/Profile/Form/PersonalPart.vue' /* webpackChunkName: "components/client-profile-form-personal-part" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileFormQualificationPart = () => import('../../components/Client/Profile/Form/QualificationPart.vue' /* webpackChunkName: "components/client-profile-form-qualification-part" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileFormUniversityPart = () => import('../../components/Client/Profile/Form/UniversityPart.vue' /* webpackChunkName: "components/client-profile-form-university-part" */).then(c => wrapFunctional(c.default || c))
export const ClientProfileFormWorkPart = () => import('../../components/Client/Profile/Form/WorkPart.vue' /* webpackChunkName: "components/client-profile-form-work-part" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionReport = () => import('../../components/Client/Session/Call/SessionReport.vue' /* webpackChunkName: "components/client-session-report" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionSettings = () => import('../../components/Client/Session/Call/SessionSettings.vue' /* webpackChunkName: "components/client-session-settings" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionVideo = () => import('../../components/Client/Session/Call/SessionVideo.vue' /* webpackChunkName: "components/client-session-video" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionVoxControls = () => import('../../components/Client/Session/Call/SessionVoxControls.vue' /* webpackChunkName: "components/client-session-vox-controls" */).then(c => wrapFunctional(c.default || c))
export const UiRadioComponent = () => import('../../components/UI/Radio/NewRadio/UiRadioComponent.vue' /* webpackChunkName: "components/ui-radio-component" */).then(c => wrapFunctional(c.default || c))
export const UiRadioGroupComponent = () => import('../../components/UI/Radio/NewRadio/UiRadioGroupComponent.vue' /* webpackChunkName: "components/ui-radio-group-component" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionCallDevicesErrors = () => import('../../components/Client/Session/Call/Devices/DevicesErrors.vue' /* webpackChunkName: "components/client-session-call-devices-errors" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionCallDevicesMicTest = () => import('../../components/Client/Session/Call/Devices/MicTest.vue' /* webpackChunkName: "components/client-session-call-devices-mic-test" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionCallDevicesOutputTest = () => import('../../components/Client/Session/Call/Devices/OutputTest.vue' /* webpackChunkName: "components/client-session-call-devices-output-test" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionDeviceSelect = () => import('../../components/Client/Session/Call/Devices/SessionDeviceSelect.vue' /* webpackChunkName: "components/client-session-device-select" */).then(c => wrapFunctional(c.default || c))
export const ClientSessionDevices = () => import('../../components/Client/Session/Call/Devices/SessionDevices.vue' /* webpackChunkName: "components/client-session-devices" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
