import {userTypes} from '@/store/user'
import {getCurrentTimeZone} from "@/helpers/getCurrentTimeZone";

const prefix = ''
const apiClientModulePath = `${prefix}/sso/api/v1/login`
const apiPsyModulePath = `${prefix}/sso/api/v1/psychologist-login`
const apiModulePathAdmin = `${prefix}/sso/api/v1/admin`
const apiClientModuleRegisterPath = `${prefix}/sso/api/v1/register`
const apiOauth = `${prefix}/sso/api/v1/oauth`

const initialState = {
	initialized: false,
	isAuth: false,
	blockedEndTime: '',
	blockedPermanent: false,
	phoneNumber: '',
	otpToken: '',
	authTokens: {
		token: '',
		refreshToken: '',
	},
	parsedToken: {},
	verifyToken: '',
	isLoading: false,
}

export const state = () => ({...initialState})

export const getters = {
	getUser(state) {
		return state.parsedToken?.user || {}
	},
}

export const mutations = {
	/**
	 *setAuthTokens
	 *
	 * @param {*} state
	 * @param {*} { token, refreshToken }
	 */
	setAuthTokens(state, {token, refreshToken}) {
		state.authTokens = {token, refreshToken}
	},

	/**
	 *setParsedToken
	 *
	 * @param {*} state
	 * @param {*} parsedToken
	 */
	setParsedToken(state, parsedToken) {
		state.parsedToken = parsedToken
	},

	/**
	 *setPhoneNumber
	 *
	 * @param {*} state
	 * @param {*} phoneNumber
	 */
	setPhoneNumber(state, phoneNumber) {
		state.phoneNumber = phoneNumber
	},

	/**
	 *setOtpToken
	 *
	 * @param {*} state
	 * @param {*} otpToken
	 */
	setOtpToken(state, otpToken) {
		state.otpToken = otpToken
	},

	/**
	 *setIsAuth
	 *
	 * @param {*} state
	 * @param {*} isAuth
	 */
	setIsAuth(state, isAuth) {
		state.isAuth = isAuth;
		state.initialized = true;
	},

	/**
	 *setBlockedEndTime
	 *
	 * @param {*} state
	 * @param {*} endTime
	 */
	setBlockedEndTime(state, endTime) {
		state.blockedEndTime = endTime
	},

	/**
	 *setBlockedPermanent
	 *
	 * @param {*} state
	 * @param {*} isBlockedPermanent
	 */
	setBlockedPermanent(state, isBlockedPermanent) {
		state.blockedPermanent = isBlockedPermanent
	},

	/**
	 *setLogOut
	 *
	 * @param {*} state
	 */
	setLogOut(state) {
		Object.assign(state, {...initialState, initialized: state.initialized})
	},

	/**
	 *setLoading
	 *
	 * @param {*} state
	 * @param {*} payload
	 */
	setLoading(state, payload) {
		state.isLoading = payload
	},
}

export const actions = {
	async resendEmailOnChange(_, email) {
		const url = `${apiPsyModulePath}/reset-email-verification`

		const data = await this.$axios.$post(url, {email}).catch((error) => {
			return error.response
		})

		return data || false
	},

	/**
	 *logoutUser
	 *
	 * @param {*} { state, commit, dispatch }
	 */
	async logoutUser({state, commit, dispatch}) {
		const url = `${prefix}/sso/api/v1/auth/logout`
		const data = await this.$axios.$post(url).catch((error) => {
			return error.response.data
		});
		console.log('!!!!LOGOUT!!!!');
		dispatch('cleanAuthData')
		dispatch('user/clearUserDataAction', {}, {root: true})
	},

	/**
	 *refreshTokenAction
	 *
	 * @param {*} { state, commit }
	 */
	async refreshTokenAction({state, commit, dispatch}) {
		const url = `${prefix}/sso/api/v1/auth/refresh-token`
		// const body = state.authTokens.refreshToken
		this.$axios.setToken(false)
		const data = await this.$axios
			.$post(url, state.authTokens.refreshToken)
			.catch((error) => {
				console.log(error)
				return Promise.reject(new Error(error))
			})
		if (data && data.token && data.refresh_token) {
			commit('setAuthTokens', {
				token: data.token,
				refreshToken: data.refresh_token,
			})
			this.$axios.setToken(data.token, 'Bearer')
			dispatch('setCookiesTokenData', {
				token: data.token,
				refreshToken: data.refresh_token,
			})
			commit('setParsedToken', this.$parseJwt(data.token))
			dispatch('user/setUserDataAction', this.$parseJwt(data.token).user, {
				root: true,
			})
			commit('setIsAuth', true)
			return Promise.resolve(data)
		} else {
			// eslint-disable-next-line
			console.warn('>> refresh error', data)
			//  will logout and clear cookies
			dispatch('logoutUser')
			return Promise.reject(new Error('refresh error', data))
		}
	},

	async setCookiesAuth({state, commit, dispatch}) {
		const token = this.$cookies.get('token');
		const refreshToken = this.$cookies.get('refreshToken');

		if (refreshToken) {
			commit('setAuthTokens', {token, refreshToken})

			try {
				await dispatch('refreshTokenAction')
			} catch (error) {
				if (this.$sentry) {
					this.$sentry.captureException(error)
				}
			}
		} else if (!refreshToken) {
			commit('setIsAuth', false);
			console.log('no cookies auth')
		}
	},

	/**
	 *setCookiesBlockedEndTime
	 *
	 * @param {*} { state, commit, dispatch }
	 */
	setCookiesBlockedEndTime({state, commit, dispatch}, blockedEndTime) {
		try {
			this.$cookies.set('blockedEndTime', blockedEndTime, {
				expires: new Date(blockedEndTime),
				path: '/',
			})
		} catch (error) {
			if (this.$sentry) {
				this.$sentry.captureException(error)
			}

			return new Error('error setting cookies', error)
		}
	},

	/**
	 *cleanAuthData
	 *
	 * @param {*} { state, commit }
	 */
	cleanAuthData({state, commit}) {
		this.$cookies.remove('token')
		this.$cookies.remove('refreshToken')
		this.$axios.setToken(false)
		commit('setLogOut')
	},

	/**
	 *setCookiesTokenData
	 *
	 * @param {*} { state, commit }
	 * @param {*} { token, refreshToken }
	 */
	setCookiesTokenData({state, commit}, {token, refreshToken}) {
		try {
			const parsedToken = this.$parseJwt(token)
			const parsedRefreshToken = this.$parseJwt(refreshToken)
			this.$cookies.set('token', token, {
				expires: new Date(parsedToken.exp * 1000),
				path: '/',
			})
			this.$cookies.set('refreshToken', refreshToken, {
				expires: new Date(parsedRefreshToken.exp * 1000),
				path: '/',
			})
		} catch (error) {
			if (this.$sentry) {
				this.$sentry.captureException(error)
			}

			return new Error('error setting cookies', error)
		}
	},
	/**
	 *verifyEmail
	 *
	 * @param {*} { commit }
	 * @param token
	 * @returns data
	 */
	async verifyEmail({commit}, token) {
		const url = `${prefix}/sso/api/v1/auth/verify-email/${token}`
		try {
			const data = await this.$axios.$get(url)
			return data
		} catch (error) {
			if (this.$sentry) {
				this.$sentry.captureException(error)
			}

			return error
		}
	},

	/* NEW Login URLs */
	async registration({commit, dispatch}, {login, username, mailing_agreement, user_timezone}) {
		const url = `${prefix}/sso/api/v1/auth/register-unified`;
		const referrer_id = this.$cookies.get('referrer_id');

		let headers = {};

		if (referrer_id) {
			headers['X-Referrer-ID'] = referrer_id;
		}

		const data = await this.$axios
			.$post(url, {login, username, mailing_agreement, user_timezone},
				referrer_id ? {
					headers: headers
				} : null
			)

		this.$cookies.remove('referrer_id');

		return data;
	},

	async b2bRegistration({commit, dispatch}, payload) {
		const url = `${prefix}/sso/api/v1/auth/register-unified-b2b-with_promocode`;

		return await this.$axios.$post(url, payload)
	},

	async signIn({commit, dispatch}, {login, password}) {
		const url = `${prefix}/sso/api/v1/auth/login-unified`
		let error = null
		const data = await this.$axios
			.$post(url, {
				login: login,
				password,
			})
			.catch((err) => {
				error = err
			})
		if (data && data.token && data.refresh_token) {
			// check for user type
			/** @type {TokenData} */
			const tokenData = this.$parseJwt(data.token)
			// const userType = tokenData.user.user_type
			// if (userType !== userTypes.psychologist) {
			// 	throw 'WRONG_USER_TYPE'
			// }

			commit('setAuthTokens', {
				token: data.token,
				refreshToken: data.refresh_token,
			})
			this.$axios.setToken(data.token, 'Bearer')
			dispatch('setCookiesTokenData', {
				token: data.token,
				refreshToken: data.refresh_token,
			})
			commit('setParsedToken', this.$parseJwt(data.token))
			dispatch('user/setUserDataAction', this.$parseJwt(data.token).user, {
				root: true,
			})
			commit('setIsAuth', true)
			return data
		} else {
			throw error
		}
	},

	async getSocialRedirectUrl({commit}, socialName) {
		try {
			return await this.$axios.$get(`${apiOauth}/authorization/${socialName}?platform=WEB&user_type=CLIENT&develop_mode=${process.env.NODE_ENV !== 'production'}`)
		} catch (error) {
			if (this.$sentry) {
				this.$sentry.captureException(error)
			}

			return error
		}
	},

	async signInWithSocial({commit, dispatch}, {socialName, code, state}) {
		// try {
		//let data = await this.$axios.$get(`${apiOauth}/sign-in/${socialName}?state=${state}&code=${code}&develop_mode=${process.env.NODE_ENV !== 'production'}`);
		const referrer_id = this.$cookies.get('referrer_id');

		let data = await this.$axios.$post(`${apiOauth}/sign-in/${socialName}`, {
			callback: {
				state, code
			},
			metadata: {
				develop_mode: process.env.NODE_ENV !== 'production',
				callback_producer: "frontend",
			},
			userdata: {
				tz: getCurrentTimeZone(),
				referrer_id: referrer_id
			}
		})

		this.$cookies.remove('referrer_id');

		if (data && data.access_token && data.refresh_token) {
			// check for user type
			/** @type {TokenData} */
			const tokenData = this.$parseJwt(data.access_token)
			const userType = tokenData.user.user_type

			commit('setAuthTokens', {
				token: data.access_token,
				refreshToken: data.refresh_token,
			})
			this.$axios.setToken(data.access_token, 'Bearer')
			dispatch('setCookiesTokenData', {
				token: data.access_token,
				refreshToken: data.refresh_token,
			})
			commit('setParsedToken', this.$parseJwt(data.access_token))
			dispatch('user/setUserDataAction', this.$parseJwt(data.access_token).user, {
				root: true,
			})
			commit('setIsAuth', true)
			return data
		}
	},

	async passwordRecovery({commit, dispatch}, {login}) {
		const url = `${prefix}/sso/api/v1/auth/password-recovery-unified`
		return await this.$axios.$post(url, {login})
	},

	//loginInstant
	async loginInstant({commit, dispatch}, {token}) {
		let data = await this.$axios.$post(`sso/api/v1/auth/login-instant`, {token})

		if (data && data.access_token && data.refresh_token) {
			// check for user type
			/** @type {TokenData} */
			const tokenData = this.$parseJwt(data.access_token)
			console.log('tokenData', tokenData);
			const userType = tokenData.user.user_type

			commit('setAuthTokens', {
				token: data.access_token,
				refreshToken: data.refresh_token,
			})
			this.$axios.setToken(data.access_token, 'Bearer')
			dispatch('setCookiesTokenData', {
				token: data.access_token,
				refreshToken: data.refresh_token,
			})
			commit('setParsedToken', this.$parseJwt(data.access_token))
			dispatch('user/setUserDataAction', this.$parseJwt(data.access_token).user, {
				root: true,
			})
			commit('setIsAuth', true)
			return data
		}

		// return data;
	},

	async getB2BCompanyBySlug(_, slug) {
		return await this.$axios.$get(`/sso/api/v1/public/b2b-companies/${slug}`)
	},

	async associateB2BPromocode({commit, dispatch}, payload) {
		return await this.$axios.$post(`${prefix}/sso/api/v1/b2b/associate-b2b-promocode`, payload)
	},

	async dissociateB2BPromocode({commit, dispatch}) {
		return await this.$axios.$post(`${prefix}/sso/api/v1/b2b/dissociate-b2b-promocode`)
	},

}
