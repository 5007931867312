const apiModulePath = '/reviews/api/v1'

export const state = () => ({
	reviewsClient: [],
})

export const mutations = {
	setReviewsClient(state, {reviewsList}) {
		state.reviewsClient = [...reviewsList]
	},
}

export const actions = {
	async getClientQuestions(ctx) {
		const url = `/reviews/api/v2/client/surveys/types/client_session_feedback`
		return await this.$axios.get(url)
	},

	async getClientAnswers(ctx, session_id) {
		const url = `/reviews/api/v2/client/reviews?session_id=${session_id}&page=1&page_size=100`
		return await this.$axios.get(url)
	},

	async sendClientFeedback(ctx, payload) {
		const url = `/reviews/api/v2/client/reviews/sessions/${payload.session_id}/feedback`
		const res = await this.$axios.post(url, payload);
	},

	async getPsychologistQuestions(ctx, isFirstSession) {
		console.log('isFirstSession', isFirstSession);
		const url = `/reviews/api/v2/psychologist/surveys/types/${isFirstSession ? 'psychologist_first_session_feedback': 'psychologist_second_session_feedback'}`
		return await this.$axios.get(url)
	},

	async getPsychologistAnswers(ctx, session_id) {
		const url = `/reviews/api/v2/psychologist/reviews?session_id=${session_id}&page=1&page_size=100`
		return await this.$axios.get(url)
	},

	async sendPsychologistFeedback(ctx, payload) {
		const url = `/reviews/api/v2/psychologist/reviews/sessions/${payload.session_id}/feedback`
		const res = await this.$axios.post(url, payload);
	},




	async sendPsyReviewSession(ctx, {data, id}) {
		const url = `${apiModulePath}/psychologist/review/${id}`
		// let error = null
		const res = await this.$axios
			.patch(url, data)

			.catch((err) => {
				console.log(err)
				throw err.response
			})

		if (res) {
			return res.data
		}
	},


	async sendClientReviewSession(ctx, data) {
		data.rating = parseFloat(data.rating);
		const url = `${apiModulePath}/client/review/${data.session_id}`
		return await this.$axios.$patch(url, data)
	},
}
